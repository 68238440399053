/* Profile Logo Styles */
.business-logo {
  max-width: 180px;
  max-height: 180px;
  height: auto;
  width: auto;
}

/* Layout Styles */
.main-layout {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.main-header-dropdown-btn .ant-btn {
  /*background-color: var(--main-bg-color); /*Bunny Books*/
  color: white; /*M-Kitchen */
  /*color: var(--primary-color); /*Bunny Books*/
}

/* Menu Styles */
.main-menu.ant-menu {
  height: calc(100vh - 5rem);
  overflow: auto;
  padding-block: 1rem;
  scrollbar-color: var(--border-color) transparent;
  scrollbar-width: thin;
}

.ant-menu .ant-menu-submenu {
  border-radius: 0;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-submenu .ant-menu-submenu-inline .ant-menu-submenu-open {
  margin-inline: 0;
  margin-block: 0;
  width: 100%;
}

.ant-menu-submenu-title {
  margin: 0 !important;
  width: 100% !important;
}

.ant-menu-item,
.ant-menu-submenu-title {
  color: white; /*M-Kitchen */
  /*color: var(--text-color) /*Bunny Books*/
  padding: 1.5rem 1.25rem;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 0 !important;
}

.main-menu .ant-menu-item-selected,
.ant-menu-light > .main-menu .ant-menu .ant-menu-item-selected {
  background-color: rgba(0, 0, 0, 0.08);
  color: white; /*M-Kitchen */
  /*color: var(--primary-color) /*Bunny Books*/
}

.main-menu .ant-menu-submenu-selected > .ant-menu-submenu-title,
.ant-menu-light
  > .main-menu.ant-menu
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title {
  background-color: rgba(0, 0, 0, 0.08);
  color: white; /*M-Kitchen */
  /*color: var(--primary-color) /*Bunny Books*/
}

.ant-menu-item-active {
  color: white !important; /*M-Kitchen */
  /*color: var(--text-color) !important /*Bunny Books*/
  box-shadow: 0px 0.5px 1px 0px rgba(0, 0, 0, 0.1);
}

.ant-statistic-title {
  margin-bottom: 0;
}
.filter-select > .ant-select-selector > .ant-select-selection-item {
  font-size: 1.3em;
  font-weight: bold;
  width: 180px;
  overflow: hidden;
}

.filter-select > .ant-select-arrow {
  color: var(--primary-color);
}

.ant-menu-item-icon svg {
  font-size: 1.125rem;
}

.menu-item-add-new {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 0.2rem;
}

/* Page Styles */
/* Page Content Styles */
.page-content {
  height: calc(100vh - 10.1925rem);
  overflow: auto;
  background: var(--bg-color1);
  width: 100%;
  padding-bottom: 5rem !important;
  scrollbar-color: var(--border-color) transparent;
}

.page-content-with-padding {
  padding: 1.5rem;
}

.page-content-with-form-buttons {
  padding-bottom: 9rem !important;
}

.page-with-column {
  display: flex;
  height: calc(100vh - 5.0625rem);
  width: 100%;
}
/* Page Header Styles */
.page-header {
  width: 100%;
  height: 5rem;
  background: var(--main-bg-color);
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  position: sticky;
  z-index: 1000;
  top: 0;
  border-bottom: 1px solid var(--border-color);
}

.page-header-text {
  font-size: 1.5rem;
}

/* Page Form Button Styles */
.page-actions-bar {
  position: fixed;
  bottom: 0;
  min-width: 100%;
  height: 5rem;
  padding-inline: 1.5rem;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.15);
  gap: 0.5rem;
  background: white;
  z-index: 100;
}

/*For pages that have padding (page-content-with-padding)*/
.page-actions-bar-margin {
  margin-left: -1.5rem;
}

.page-actions-btn {
  display: flex;
  min-width: 5rem;
  height: 2.5rem !important;
  padding: 0rem 1rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.page-form-wrapper {
  width: 85%;
  display: inline-block;
  vertical-align: text-top;
}

@media screen and (max-width: 1640px) {
  .page-form-wrapper {
    width: 100%;
  }
}

.ob-page-form-wrapper {
  width: 80%;
  display: inline-block;
  vertical-align: text-top;
}

@media screen and (max-width: 1390px) {
  .ob-page-form-wrapper {
    width: 100%;
  }
}

/* Table Styles */
/* General Table Styles */
.item-details-table {
  overflow: auto;
  scrollbar-color: var(--border-color) transparent;
  scrollbar-width: thin;
}

.item-details-table .ant-table {
  min-width: 1040px;
}

.item-details-table .ant-form-item {
  margin-bottom: 0;
}

.item-details-description.ant-input {
  border-color: transparent;
}

.item-details-description.ant-input:hover {
  border-color: var(--primary-color) !important;
}

.ant-table-selection-col,
.ant-table-selection-column {
  display: none;
}

.table-list-item {
  display: list-item;
}

.table-list-item-title {
  font-weight: bold;
  padding: 2px;
}

.table-list-item-description {
  padding-left: 4px;
  font-size: 0.85em;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  cursor: pointer;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  background: white;
  font-size: 0.8125rem;
  text-transform: uppercase;
  font-weight: normal;
  line-height: 0.9525rem;
  border-color: var(--border-color);
  height: 3rem;
}

.ant-table-wrapper {
  width: 100%;
}

.table-variant .ant-table-thead > tr > th,
.table-variant .ant-table-thead > tr > td {
  background-color: var(--main-bg-color);
}

.header-bg-color-table .ant-table-thead > tr > th,
.header-bg-color-table .ant-table-thead > tr > td {
  background-color: var(--main-bg-color);
}

.table-header-search-icon {
  font-size: 1rem;
  cursor: pointer;
}

.header-less-table .ant-table-thead {
  display: none;
}

.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child,
.ant-table-row > .ant-table-cell:first-child {
  padding-left: 1.5rem;
}

.table-actions-header {
  background-color: white;
  height: 3.375rem;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  padding-inline: 1.5rem;
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
}

.table-actions-header .ant-select-item-option {
  padding-top: 1rem;
}

.payment-table .ant-table-tbody > tr > td,
.item-details-table .ant-table-tbody > tr > td {
  vertical-align: top;
  padding-bottom: 0;
}

.credit-table .ant-table-tbody > tr > td {
  vertical-align: top;
}

.payment-table .ant-table-thead > tr > th,
.payment-table .ant-table-thead > tr > td {
  border-top: 1px solid var(--border-color);
}

/* .table-actions-header span {
  margin-right: 2rem;
} */

.border-less-input,
.border-less-input .ant-select-selector {
  border: 1px solid transparent !important;
}

.border-less-input:hover {
  border: 1px solid var(--primary-color) !important;
}
.ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell {
  background: #f5f5f5;
}

.table-actions-select .ant-select-selector {
  border: none !important;
  box-shadow: transparent;
}

/*Fixed Table Header*/
.main-table .ant-table-container table > thead > tr > th {
  position: sticky;
  top: 0;
  z-index: 100;
}

.column-table .ant-table-container table > thead > tr > th {
  position: sticky;
  top: 0;
  z-index: 100;
}

/*For Item Details Table */
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > :first-child {
  text-align: start;
}

.item-details-select {
  height: 4rem;
  font-size: 0.813rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.item-details-select-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.813rem;
}

.item-details-select-list .stock-on-hand {
  color: var(--primary-color);
}

/* Columns Styles */
/* Main Content Styles */
.main-content {
  display: flex;
  flex: 1;
}

/* Columns Styles */
.list-column {
  border-right: 1px solid #e4e4e4;
  flex-shrink: 0;
  width: 350px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-right: 5px;
  height: 93vh;
}

/* Column Header Styles */
.list-column-header-row,
.list-column-header-row-column {
  justify-content: space-between;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
  width: 15rem;
  padding: 0.5rem 0.5rem;
}

/* Column Full Row Styles */
.list-column-full-row {
  flex-grow: 1;
  background-color: white;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  overflow: scroll;
}

/* Content Column Styles */
.content-column {
  height: 100%;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  min-width: 558px;
}

.content-column-header-row {
  background-color: white;
  display: flex;
  min-height: 5rem;
  max-height: 5rem;
  min-width: 32.281rem;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem 0 1.5rem;
  border-left: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color) !important;
}

.content-column-header-row-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-column-header-row-actions {
  display: flex;
  height: 100%;
  align-items: center;
}

.content-column-header-row-actions div {
  min-width: 4rem;
  display: flex;
  justify-content: center;
}

.content-column-header-row-actions > :first-child {
  border-right: 1px solid var(--border-color);
  cursor: pointer;
}

.content-column-header-row-actions > :last-child {
  margin-right: -1.5rem;
}

.content-column-header-row-text > :first-child {
  font-size: 0.813rem;
}

.content-column-header-row-text > :last-child,
.recent-transaction-container p {
  font-size: 1.125rem;
}

.content-title-block {
  height: 4.5rem;
}

.content-column-action-row {
  min-height: 3.5rem;
  display: flex;
  align-items: center;
  min-width: 32.281rem;
  overflow: hidden;
  border: 1px solid var(--border-color);
  border-top: none;
  background-color: var(--main-bg-color);
}

.content-column-action-row div {
  height: 100%;
  display: flex;
  align-items: center;
  min-width: 3rem;
  justify-content: center;
  padding: 0 1rem;
  border-right: 1px solid var(--border-color);
  cursor: pointer;
  gap: 0.5rem;
}
.content-column-action-row div.disable {
  cursor: not-allowed;
  opacity: 60%;
}
.content-column-action-row div.disable:hover {
  color: var(--text-color);
}
.content-column-action-row div:hover {
  color: var(--primary-color);
  transition: all 0.3s;
}

.content-column-brief-row {
  padding-top: 5px;
  padding-bottom: 5px;
}

.content-column-full-row {
  flex-grow: 1;
  /* justify-content: center; */
  /* align-items: flex-start; */
  background-color: white;
  padding: 1.5rem;
  flex-wrap: wrap;
  padding-bottom: 3.125rem;
  overflow: auto;
  transition: all 1s;
  z-index: 100;
  scrollbar-color: var(--border-color) transparent;
}

.transaction-table {
  transition: width 0.2s ease;
}

.txn-detail-column {
  width: 0;
  overflow: hidden;
  background-color: white;
  transition: width 0.2s ease;
  height: calc(100vh - 5.1925rem);
}

.column.close {
  width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transition: width 0.2s ease;
}

.column.open {
  width: 22.625rem;
  transition: width 0.2s ease;
}

.txn-detail-column.open {
  width: 37%;
  height: calc(100vh - 5.1925rem);
  background-color: white !;
  border-left: 1px solid var(--border-color);
  z-index: 100;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  flex-shrink: 0;
}

.acc-journal-accordion {
  height: 0;
  overflow: hidden;
  background-color: white;
  transition: height 0.2s ease;
  width: 100%;
}

.acc-journal-accordion.open {
  width: 100%;
  height: 15rem;
  transition: height 0.2s ease;
  background-color: white;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 1rem;
}

.txn-details-body {
  overflow: auto;
  border-top: 1px solid #eee;
  padding-top: 20px;
}

.txn-details-content {
  overflow-x: hidden;
  height: calc(100vh - 11.938rem);
  overflow-y: auto !important;
  padding-bottom: 4rem;
  scrollbar-color: var(--border-color) transparent;
  scrollbar-width: thin;
}

.txn-details-table .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  height: auto !important;
  padding: 0.5rem;
}

.txn-details-table .ant-table-tbody > tr > td {
  padding: 0.5rem 16px;
}

@media screen and (max-width: 700px) {
  .list-column {
    display: none;
  }
  .content-column {
    display: block;
  }
}

.badge-pill {
  padding: 5px 15px;
  font-size: 14px;
  border-radius: 10rem;
  background-color: rgba(89, 166, 53, 0.3);
  width: fit-content;
}

/* Title and Description Styles */
.content-title-block {
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.content-title-main {
  padding-top: 4px;
  font-weight: bold;
  font-size: 1.4em;
}

.content-title-sub {
  padding-top: 3px;
  padding-right: 6px;
  font-size: 0.9em;
}

.content-description {
  margin-top: 4px;
  font-size: 0.9em;
}

.column-width1 {
  width: 20.5rem;
  overflow: auto;
  scrollbar-color: var(--border-color) transparent;
  scrollbar-width: thin;
  border-right: 1px solid var(--border-color);
}

.column-width2 {
  width: 22.625rem;
  height: calc(100vh - 10.1925rem);
  overflow: auto;
  scrollbar-color: var(--border-color) transparent;
  border-right: 1px solid var(--border-color);
  scrollbar-width: thin;
}

.column-form-header {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 1.5rem;
}

/* Wrapper and Column Width Styles */
.wrapper {
  display: flex;
  flex-direction: row;
}

/* Dynamic Delete Button Styles */
.dynamic-delete-button {
  height: 1rem;
}

/* Form, Inputs, and Button Styles */
.login-form {
  margin: auto;
  padding: 10px;
}

.form {
  padding: 10px;
}

.ant-form-item .ant-form-item-label > label {
  white-space: pre-line;
}

.ant-select-selector,
.ant-select-single {
  height: 2.5rem;
}

.ant-input {
  height: 2.5rem;
}

.ant-btn,
.ant-btn.ant-btn.ant-btn-icon-only {
  border-radius: 0.3rem;
  height: 2rem;
}

.ant-picker-outlined {
  height: 2.5rem;
  width: 100%;
}

.ant-radio-wrapper {
  font-size: var(--label-font-size);
}

.add-row-item-btn {
  border: none;
  box-shadow: none;
}

.ant-input-affix-wrapper > input.ant-input {
  height: 100%;
}

.ant-input-affix-wrapper {
  height: 2.5rem;
  max-height: 2.5rem;
}

.ant-input-number .ant-input-number-input,
.ant-input-search .ant-input-search-button {
  height: 2.5rem;
}

.margin-less-input {
  margin: 0;
}

/*Icon Styles*/
.plus-circle-icon {
  color: var(--primary-color);
}

.action-icon {
  color: var(--primary-color);
  font-size: 1rem;
}

.action-icon {
  color: var(--primary-color);
  font-size: 1rem;
}

.action-placeholder {
  width: 1rem;
  height: 1rem;
}

.ant-dropdown {
  background-color: white;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active {
  background-color: var(--primary-color) !important;
  color: white;
}

.active-status {
  margin-left: 0.5rem;
}

/*Attachment Upload Styles*/
.attachment-upload-container {
  margin-top: 3.5rem;
}

.attachment-upload-container p {
  font-size: 0.625rem;
}

.attachment-upload-btn {
  height: 2.5rem;
}

/* Ant Modal Styles */
.ant-modal .ant-modal-title {
  height: 4rem;
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 1.125rem;
  background-color: var(--main-bg-color);
  margin: -1.5rem -1.5rem 1.5rem -1.5rem;
  padding-left: 1.5rem;
  border-radius: 0.3125rem;
}

.ant-modal .ant-modal-content {
  padding: 1.5rem;
}

.ant-modal .ant-modal-footer {
  margin: 1.5rem -1.5rem -1.5rem -1.5rem;
  height: 4.5rem;
  background-color: white;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  border-radius: 0.3125rem;
  gap: 0.5rem;
  border-top: 1px solid var(--border-color);
}

.ant-modal-footer .ant-btn:first-child {
  order: 2;
}

.ant-modal-footer .ant-btn:last-child {
  order: 1;
}

.ant-modal .ant-modal-footer > .ant-btn + .ant-btn {
  margin-inline-start: 0;
}

.ant-select.ant-select-in-form-item {
  height: 2.5rem;
}

.fixed-col-first {
  position: sticky !important;
  background-color: white !important;
  left: 0px !important;
  z-index: 3 !important;
}

.fixed-col-second {
  position: sticky !important;
  background-color: white !important;
  left: 170px !important;
  z-index: 2 !important;
}

.fixed-col-second-2 {
  position: sticky !important;
  background-color: white !important;
  left: 149px !important;
  z-index: 2 !important;
}

/*Text Styles*/

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

/*Ant Tag*/
.ant-tag {
  background-color: var(--tag-color);
  border-radius: 0.313rem;
  padding: 3px 8px;
}

/*Others*/
.table-view {
  margin: 2.5rem 3.563rem;
}

.toggle-pdf-view {
  margin-block: 3.563rem;
  display: flex;
  justify-content: space-between;
  padding-inline-end: 2.5rem;
}

.active-status {
  margin-left: 0.5rem;
  border: none;
}

/* .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  height: 2.1rem;
} */

.add-items-container {
  display: flex;
}

.items-menu-section {
  width: 50%;
}

.selected-items-section {
  width: 50%;
  height: 28.375rem;
}

.items-list,
.selected-items-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-right: 1px solid var(--border-color);
  height: 32.625rem;
  overflow: auto;
  box-sizing: border-box;
  scrollbar-color: var(--border-color) transparent;
  scrollbar-width: thin;
}

.items-list li {
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  display: flex;
  padding-left: 1.5rem;
  margin-bottom: 1px;
}

.items-list li .item-details-select {
  display: flex;
  justify-content: center;

  width: 25.813rem;
}

.items-list li:hover {
  background-color: var(--hover-bg);
}

.item-selected-icon {
  font-size: 17px;
  width: 17px;
}

.item-selected-icon.default {
  color: var(--text-color);
  opacity: 50%;
}

.item-selected-icon.selected {
  color: var(--light-green);
  opacity: 100%;
}

.selected-items-header {
  height: 4.5rem;
  border-bottom: 1px solid var(--border-color);
  padding: 1.5rem;
  align-items: center;
  margin-top: -0.5rem;
}

.selected-items-list li {
  padding: 0 1.5rem 0 1.5rem;
  height: 4.063rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected-items-list {
  padding-bottom: 4.5rem;
  scrollbar-color: rgba(128, 128, 128, 0.5) white;
  scrollbar-width: thin;
}

.text-center {
  text-align: center !important;
  margin-top: 40%;
  margin-bottom: 60%;
}

.selected-items-count {
  width: 2.188rem;
  font-size: 0.75rem;
  text-align: center;
  border: 1px solid var(--border-color);
  vertical-align: middle;
  border-radius: 1.25rem;
}
.added-item {
  background-color: #f0f0f0 !important;
}

/* .added-item:hover {
  background-color: rgba(89, 166, 53, 0.3) !important;
} */

.items-search-container {
  height: 4.5rem;
  background-color: var(--main-bg-color);
  padding: 1rem 1.5rem;
  box-sizing: border-box;
  margin-top: -0.5rem;
}

.items-search-container .ant-input-search-button {
  height: 2.5rem;
}

.add-items-modal .ant-modal-footer {
  width: 50% !important;
  margin: left;
  margin-left: 50%;
  position: absolute;
  top: 35.1rem;
  right: 1.5rem;
  z-index: 10;
}

.add-items-modal .ant-modal-content {
  padding: 0;
  overflow: hidden;
}

.add-items-modal .ant-modal-title {
  margin: 0;
  border-bottom: 1px solid var(--border-color);
}

/*Statistic Card*/
.ant-card .ant-card-body {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  max-height: 100%;
}
/*Ant Statistic Styles*/
.ant-statistic .ant-statistic-content {
  font-size: 1rem;
}

.rc-virtual-list
  .rc-virtual-list-holder
  .rc-virtual-list-holder-inner
  > div:last-child {
  /* margin-bottom: 2rem; */
  transform: none !important;
}

.input-only-table .ant-table-cell {
  padding: 16px 5px 0px 5px !important;
}

/*Accordion Styles*/
.bill-receives-container {
  border: 1px solid var(--border-color);
  max-height: 99999;
  border-radius: var(--border-radius);
  box-sizing: border-box;
  padding-inline: 1rem;
  padding-top: 0.3rem;
}

.nav-bar {
  height: 2.5rem;
  border-radius: var(--border-radius);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid var(--border-color);
}
.collapsed {
  border-bottom: 1px solid transparent;
  transition: all 400ms ease-in-out;
}

.nav-tabs {
  display: inline-flex;
  list-style: none;
  padding: 0;
}

.nav-tabs .active {
  border-bottom: 2px solid var(--primary-color);
  font-weight: 500;
}

.nav-tabs .nav-link {
  padding: 0;
  height: 100%;
  min-width: 3.313rem;
  text-align: center;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.nav-link .bill {
  padding-inline: 0.375rem;
  border-radius: var(--border-radius);
  color: white;
  background-color: var(--primary-color);
  font-size: 0.688rem;
  margin-left: 0.25rem;
}

.bill-table .ant-table-tbody > tr > td,
.bill-table .ant-table-thead > tr > th {
  height: 2.5rem !important;
  position: sticky;
  top: 0;
  z-index: 100000000000000000000000;
  background-color: white;
}

.bill-table .ant-table-tbody > tr > td,
.bill-table .ant-table-thead > tr > th {
  padding: 0 1rem;
}

.bill-table .ant-table-tbody > tr:last-child > td {
  border-bottom: none;
}

.bill-tab {
  display: flex;
  width: 100%;
  min-height: 2.5rem;
  align-items: center;
  justify-content: center;
}

.receive-tab {
  display: flex;
  width: 100%;
  min-height: 2.5rem;
  align-items: center;
  justify-content: center;
}

.tab-divider {
  top: 0.3rem;
  height: 2rem;
  margin-right: 1rem;
}
.content-wrapper {
  height: auto;
  overflow: hidden;
  max-height: 0;
  transition: all 400ms ease-in-out;
  padding-top: 0;
  padding-bottom: 0.3rem;
}

.show {
  max-height: 13.375rem;
  opacity: 100%;
  padding-top: 0.2rem;
  overflow: auto;
  scrollbar-color: var(--border-color) transparent;
}

/*Supplier/Customer Search Input */
.search-btn .ant-btn,
.search-btn.ant-btn.ant-btn.ant-btn-icon-only {
  height: 2.5rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 4rem;
}

.search-input {
  /* border-top-right-radius: 0;
  border-bottom-right-radius: 0; */
  padding-right: 0;
}

.search-type-btn.ant-btn {
  height: 2.5rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(--main-bg-color);
}

.search-input-modal.ant-input-search
  .ant-input-group
  .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 0 !important;
}
.ant-input-search-disabled .ant-input {
  pointer-events: none;
}

.item-details-table .ant-table-cell {
  padding: 16px 7px 8px 7px !important;
}

.form-mask {
  opacity: 0.2;
  pointer-events: none;
}

.delete-icon,
.edit-icon {
  font-size: 0.813rem;
  cursor: pointer;
}

.delete-icon:hover {
  color: var(--red);
}

.edit-icon:hover {
  color: var(--primary-color);
}

/* Change Password Page */
/* .login-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: white;
  justify-content: center;
  align-items: center;
} */

.login-form-container {
  text-align: center;
  width: 23rem;
  height: auto;
  margin: 0 auto;
  margin-top: 8rem;
  border-radius: 0.5rem;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.16);
  background-color: var(--main-bg-color);
  padding: 1.5rem;
}

.login-btn {
  width: 100%;
}

.journal-table .ant-table-tbody > tr > td,
.journal-table .ant-table-thead > tr > th,
.journal-table .ant-table-thead > tr > td,
.journal-table .ant-table-summary > tr > td {
  padding-block: 4px;
  height: auto;
}
.journal-table .ant-table-summary > tr > td {
  border-bottom: 0;
}
/* .journal-table .ant-table-thead > tr > th {
  border-top: 1px solid var(--border-color);
} */

.auto-complete {
  width: 500px !important;
}

.product-select {
  width: 350px !important;
}

.item-details-dropdown {
  min-width: 230px !important;
}

.new-manual-journal-table {
  margin-top: 2rem;
}

.new-manual-journal-table .ant-table-cell {
  padding: 16px 5px 0px 5px !important;
  vertical-align: top;
}

.new-manual-journal-table-footer {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  min-height: 12rem;
  height: auto;
  box-sizing: border-box;
}

.new-manual-journal-table-footer table {
  background-color: var(--main-bg-color);
  width: 35rem;
  height: 100%;
  padding: 1.5rem;
  box-sizing: border-box;
  border-radius: 0.313rem;
}

.new-manual-journal-table-footer .add-row-button {
  background-color: var(--main-bg-color);
  color: black;
  height: 2.5rem;
  border-color: var(--main-bg-color);
}

.new-manual-journal-table-footer .add-row-button .add-row-icon {
  color: var(--primary-color);
}

.attachment-upload p {
  font-size: 0.625rem;
}

.attachment-upload-button {
  height: 2.5rem;
}
.custom-select {
  width: 100% !important;
  /* height: 100%; */
}

.new-journal-buttons-container {
  display: flex;
  gap: 0.1rem;
}

.new-journal-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.new-journal-dropdown {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 4rem;
}

/* compact columns */

.column-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-journal-modal .ant-modal-content .ant-modal-body {
  padding-left: 1rem;
}

.tree-indicator-icon {
  color: var(--primary-color);
  position: absolute;
  bottom: 1.625rem;
}

.content-column-balance-detail {
  height: 7.938rem;
  min-height: 7.938rem;
  background-color: rgba(89, 166, 53, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  padding-left: 1.5rem;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px dashed var(--primary-color);
}

.content-column-balance-detail {
  height: 7.938rem;
  min-height: 7.938rem;
  background-color: rgba(89, 166, 53, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  padding-left: 1.5rem;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px dashed var(--primary-color);
}

.content-column-balance-detail .closing-balance-text {
  font-size: 1.25rem;
  color: var(--primary-color);
}

.content-column-balance-detail .closing-balance-description {
  margin-top: 0.5rem;
  font-size: 0.75rem;
}

.transaction-table .ant-table-thead > tr > th,
.transaction-table .ant-table-thead > tr > td {
  background-color: var(--main-bg-color);
}

.recent-transaction-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.product-details-section {
  margin-bottom: 3.5rem;
}

.product-details-header-row {
  border: none;
}

.product-details-content-column-full-row {
  padding-top: 0;
}

.overview-table {
  margin-top: 2rem;
}

.overview-data {
  padding-top: 1rem;
  font-size: 0.75rem;
  opacity: 70%;
}

.ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}

.stock-locations-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  text-align: center !important;
}

.product-variant-delete-icon:hover {
  color: var(--red);
}
